<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Chi tiết instant feedback {{ feedback_title }}
      </h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogDetail"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm chi tiết</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Score Start</th>
                    <th scope="col">Score End</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(detail, i) in details">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg mb-0"
                        >
                          {{ detail.score_start }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg mb-0"
                        >
                          {{ detail.score_end }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editDetail(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteDetail(detail.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!details.length">
                    <tr>
                      <td colspan="5" style="text-align: center">No Data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogDetail" max-width="1400px" scrollable persistent>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form_detail === 'add'"
                >Thêm chi tiết</span
              >
              <span class="headline" v-else>Sửa </span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogDetail = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col offset="2" cols="10" class="p-0 pl-4 pb-2">
                      <span class="red--text"
                        >Hãy lưu ý nhập khoảng điểm để không trùng với các
                        khoảng điểm hiện tại</span
                      >
                    </v-col>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Nhập % điểm:</label></v-col
                    >
                    <v-col cols="1" class="p-0 m-0 ml-4">
                      <v-text-field
                        type="number"
                        dense
                        filled
                        v-model="score_start_input"
                        min="0"
                        max="100"
                      ></v-text-field>
                    </v-col>
                    <v-icon class="ml-6 mr-6 mb-6"
                      >mdi-arrow-right-bold-outline</v-icon
                    >
                    <v-col cols="1" class="p-0 m-0">
                      <v-text-field
                        type="number"
                        dense
                        filled
                        v-model="score_end_input"
                        min="0"
                        max="100"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Nhập feedback:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-row>
                        <v-col cols="5" class="p-0">
                          <v-textarea
                            outlined
                            auto-grow
                            dense
                            rows="1"
                            class="ml-4 p-0 mt-4 text-body-1"
                            v-model="content_feedback_input"
                            placeholder="Nhập ..."
                          ></v-textarea>
                        </v-col>
                        <v-btn
                          color="cyan"
                          class="ma-2 mt-4 font-weight-bold"
                          outlined
                          small
                          @click="addContentFeedback"
                        >
                          <v-icon small left>mdi-check</v-icon> Lưu
                        </v-btn>
                        <v-btn
                          color="#E53935"
                          class="mt-4 font-weight-bold"
                          outlined
                          small
                          @click="
                            () => {
                              this.content_feedback_input = '';
                              this.index_content = null;
                            }
                          "
                        >
                          <v-icon small left>mdi-close</v-icon> Hủy nhập
                        </v-btn>
                      </v-row>
                      <ul
                        style="background-color: #f3f6f9; border-radius: 5px"
                        class="pt-2 pb-2"
                        v-if="content_feedbacks.length > 0"
                      >
                        <template v-for="(content, i) in content_feedbacks">
                          <li class="d-flex mt-4" :key="i">
                            <v-btn
                              color="#FFAB00"
                              outlined
                              small
                              @click="editContentFeedback(i)"
                            >
                              <v-icon small
                                >mdi-file-document-edit-outline</v-icon
                              >
                            </v-btn>
                            <v-btn
                              color="#D84315"
                              class="ml-1"
                              outlined
                              small
                              @click="deleteContentFeedback(i)"
                            >
                              <v-icon small>mdi-close</v-icon>
                            </v-btn>
                            <p class="text-body-1 mb-0 ml-2 mr-2 text-justify">
                              {{ content }}
                            </p>
                          </li>
                        </template>
                      </ul>
                      <div
                        v-if="content_feedbacks.length <= 0"
                        style="
                          background-color: #f3f6f9;
                          border-radius: 5px;
                          min-height: 30px;
                          padding-top: 5px;
                        "
                      >
                        <p style="text-align: center">No data</p>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row class="pt-4">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Icons:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <vue-dropzone
                        style="min-height: 100px"
                        ref="imgDropZone"
                        id="dropzone"
                        :options="dropzoneOptions"
                        @vdropzone-complete="afterComplete"
                      >
                      </vue-dropzone>
                      <v-row v-if="icon_feedbacks.length" class="mt-1">
                        <template v-for="(icon, index) in icon_feedbacks">
                          <v-col cols="3" :key="index" class="hover-photo">
                            <v-icon
                              class="float-right item-photo"
                              color="red"
                              @click="deleteIcon(index)"
                              >mdi-close</v-icon
                            >
                            <v-img contain :src="icon"></v-img>
                          </v-col>
                        </template>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_detail === 'add'"
              @click="btnAddOrEditDetail(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_detail === 'update'"
              @click="btnAddOrEditDetail(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogDetail = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import vue2Dropzone from "vue2-dropzone";
export default {
  name: "Detail",
  components: {
    vueDropzone: vue2Dropzone,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      feedback_title: "",
      details: [],
      feedback_detail_id: null,
      type_form_detail: null,
      dialogDetail: false,
      score_start_input: null,
      score_end_input: null,
      content_feedback_input: "",
      index_content: null,
      content_feedbacks: [],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        acceptedFiles: ".jpg, .jpeg, .png",
        addRemoveLinks: false,
        maxFilesize: 0.3,
      },
      icon_feedbacks: [],
    };
  },
  computed: {
    feedback_id: function () {
      return this.$route.params.id;
    },
  },
  created() {
    this.getAllInstantFeedback();
  },
  methods: {
    async getAllInstantFeedback() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get(
          "prep-app/instant-feedback/" + this.feedback_id + "/detail"
        );
        if (res.status === 200) {
          vm.feedback_title = res.data.instant_feedback;
          vm.details = res.data.details;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    openDiaLogDetail() {
      this.score_start_input = null;
      this.score_end_input = null;
      this.content_feedbacks = [];
      this.icon_feedbacks = [];
      this.type_form_detail = "add";
      this.dialogDetail = true;
    },
    validateData() {
      let flat = true;
      if (this.score_start_input === "" || this.score_start_input == null) {
        this.$toasted.error("Nhập điểm bắt đầu !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.score_end_input === "" || this.score_end_input == null) {
        this.$toasted.error("Nhập điểm kết thúc !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.score_start_input != null && this.score_end_input != null) {
        if (
          parseInt(this.score_start_input) < 0 ||
          parseInt(this.score_end_input) < 0
        ) {
          this.$toasted.error("Các ô nhập điểm không được nhỏ hơn 0 !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
        if (
          parseInt(this.score_start_input) > 100 ||
          parseInt(this.score_end_input) > 100
        ) {
          this.$toasted.error("Các ô nhập điểm không được lớn hơn 100 !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
        if (parseInt(this.score_start_input) > parseInt(this.score_end_input)) {
          this.$toasted.error("Điểm bắt đầu phải nhỏ hơn điểm kết thúc !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
        let check_score_start = this.score_start_input.toString().split(".");
        if (check_score_start.length >= 2) {
          if (check_score_start[1].toString().length > 2) {
            this.$toasted.error(
              "Giới hạn 2 chữ số sau dấu . (điểm bắt đầu) !!",
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              }
            );
            flat = false;
          }
        }
        let check_end_input = this.score_end_input.toString().split(".");
        if (check_end_input.length >= 2) {
          if (check_end_input[1].toString().length > 2) {
            this.$toasted.error(
              "Giới hạn 2 chữ số sau dấu . (điểm kết thúc) !!",
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              }
            );
            flat = false;
          }
        }
      }
      if (this.content_feedbacks.length <= 0) {
        this.$toasted.error("Nhập nội dung feedback !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    async btnAddOrEditDetail(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let vm = this;
      let data = {
        score_start: this.score_start_input,
        score_end: this.score_end_input,
        contents: this.content_feedbacks,
        icons: this.icon_feedbacks,
      };
      if (type === 1) {
        await ApiService.post(
          "prep-app/instant-feedback/" + this.feedback_id + "/detail",
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.getAllInstantFeedback();
              vm.$toasted.success("Tạo thành công !", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.dialogDetail = false;
            }
          })
          .catch(function (error) {
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        await ApiService.put(
          "prep-app/instant-feedback/" +
            this.feedback_id +
            "/detail/" +
            this.feedback_detail_id,
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.getAllInstantFeedback();
              vm.$toasted.success("Sửa thành công !", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.dialogDetail = false;
            }
          })
          .catch(function (error) {
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    editDetail(index) {
      let data = this.details[index];
      this.score_start_input = data.score_start;
      this.score_end_input = data.score_end;
      this.content_feedbacks =
        data.contents == null ? [] : JSON.parse(JSON.stringify(data.contents));
      this.icon_feedbacks =
        data.icons == null ? [] : JSON.parse(JSON.stringify(data.icons));
      this.feedback_detail_id = data.id;
      this.type_form_detail = "update";
      this.dialogDetail = true;
    },
    deleteDetail(detail_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete(
            "prep-app/instant-feedback/" +
              this.feedback_id +
              "/detail/" +
              detail_id
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllInstantFeedback();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    addContentFeedback() {
      if (
        this.content_feedback_input === null ||
        this.content_feedback_input === ""
      ) {
        this.$toasted.error("Không được bỏ trống !", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      if (this.index_content == null) {
        this.content_feedbacks.push(this.content_feedback_input);
      } else {
        this.content_feedbacks[this.index_content] =
          this.content_feedback_input;
      }
      this.index_content = null;
      this.content_feedback_input = "";
    },
    editContentFeedback(index) {
      this.index_content = index;
      this.content_feedback_input = this.content_feedbacks[index];
    },
    deleteContentFeedback(index) {
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.content_feedbacks.splice(index, 1);
        }
      });
    },
    async afterComplete(file) {
      if (file.status === "error") {
        // this.$toasted.error('Ảnh quá ' +(this.dropzoneOptions.maxFilesize*1000)+'kb hoặc không hợp lệ', { theme: "toasted-primary", position: "top-right", duration : 4000 });
        this.$toasted.error("File không đúng định dạng", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      } else {
        let vm = this;
        try {
          vm.is_call_api = true;
          let formData = new FormData();
          formData.append("file", file);

          let res = await ApiService.post(
            "prep-app/instant-feedback/upload-icon-detail",
            formData
          );
          if (res.status === 200) {
            vm.is_call_api = false;
            vm.icon_feedbacks.push(res.data.url);
            vm.$toasted.success("Upload icon thành công", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
          }
        } catch (error) {
          if (error) {
            vm.is_call_api = false;
            vm.$toasted.error(
              "Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ",
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              }
            );
          }
        }
      }
      this.$refs.imgDropZone.removeFile(file);
    },
    deleteIcon(indexIcon) {
      let vm = this;
      Swal.fire({
        title: "Bạn có chắc chắc muốn xóa icon này?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Xác nhận",
      }).then((result) => {
        if (result.value) {
          vm.icon_feedbacks.splice(indexIcon, 1);
        }
      });
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
